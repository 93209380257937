/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import EnviarRespostas from "./EnviarRespostas"
import Pergunta from "./perguntas/Pergunta";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import PesquisaIndisponivel from "./PesquisaIndisponivel";
import api from "../services/api";
import Swal from "sweetalert2";

const Formulario = () => {

    const [pesquisaExist, setPesquisaExist] = useState(false);
    const [pesquisa, setPesquisa] = useState(null);
    const [loader, setLoader] = useState(true);
    const [loaderMessage] = useState('Verificando pesquisa solicitada...');
    const [showMessage, setShowMessage] = useState(false);
    const [msgPesquisaIndisponivel, setmsgPesquisaIndisponivel] = useState("");
    const [typePesquisaIndisponivel, setTypePesquisaIndisponivel] = useState("");
    const [countGetPesquisa, setCountGetPesquisa] = useState(1);
    const [formValues, setFormValues] = useState({});
    const [idRealizacaoPesquisa, setIdRealizacaoPesquisa] = useState(window.location.pathname.split('/')[1]);
    const [tokenRealizacaoPesquisa, setTokenRealizacaoPesquisa] = useState(window.location.pathname.split('/')[2]);
    const [loaderFormSubmit, setLoaderFormSubmit] = useState(false);
    const [messageSubmitButton, setMessageSubmitButton] = useState("Enviar Respostas");

    useEffect(() => {
        setIdRealizacaoPesquisa(window.location.pathname.split('/')[1]);
        setTokenRealizacaoPesquisa(window.location.pathname.split('/')[2]);
        fetchApiPerguntas();
    }, []);

    useEffect(() => {
        if (pesquisaExist) {
            api.post(`/perguntas-realizacao/${idRealizacaoPesquisa}/iniciar`, {}, {
                headers: {
                    'x-token-pesquisa': tokenRealizacaoPesquisa
                },
                timeout: 5000, //5 SEGUNDOS
            })
        }
    }, [pesquisaExist])


    useEffect(() => {
        if (countGetPesquisa > 0 && countGetPesquisa < 5) {
            fetchApiPerguntas();
        } else {
            if (countGetPesquisa === 0) {
                setLoader(false);
                setPesquisaExist(false);
                setShowMessage(true);
                setmsgPesquisaIndisponivel("Ocorreu um erro inesperado. Por favor, atualize a página e tente novamente.");
                setTypePesquisaIndisponivel("danger");
            }
        }
    }, [countGetPesquisa]);

    const fetchApiPerguntas = async () => {
        try {
            const { data, status } = await api.get(`/perguntas-realizacao/${idRealizacaoPesquisa}`,
                {
                    headers: {
                        'x-token-pesquisa': tokenRealizacaoPesquisa
                    },
                    timeout: 10000, //10 SEGUNDOS
                })

            if (status !== 200) {
                setmsgPesquisaIndisponivel("Ocorreu um erro inesperado. Por favor, atualize a página e tente novamente.");
                setTypePesquisaIndisponivel('danger');
                setShowMessage(true);
                setPesquisaExist(false);
                setLoader(false);
                setCountGetPesquisa(0);
                return;
            }

            if (data.realizacao.id_pesquisa === "") {
                setPesquisaExist(false);
                setmsgPesquisaIndisponivel("Nenhuma pesquisa foi encontrada.");
                setTypePesquisaIndisponivel('danger');
                setShowMessage(true);
            } else if (data.realizacao.status === "concluido") {
                setPesquisaExist(false);
                setmsgPesquisaIndisponivel("Pesquisa já respondida!");
                setTypePesquisaIndisponivel('success');
                setShowMessage(true);
                setTimeout(() => {
                    window.close();
                }, 3000)

            } else {
                setPesquisa(data);
                setPesquisaExist(true);
            }
            setLoader(false);
        } catch (error) {
            if (error.name === "AxiosError" && error.response === undefined && countGetPesquisa > 0 && countGetPesquisa < 4) {
                setCountGetPesquisa(countGetPesquisa + 1);
                setPesquisaExist(false);
                setmsgPesquisaIndisponivel(`Tentando comunicação com o Servidor. Estamos tentando capturar sua pesquisa. TENTATIVA: ${countGetPesquisa}.`);
                setTypePesquisaIndisponivel('danger');
                setShowMessage(true);
            } else {
                setLoader(false);
                setPesquisaExist(false);
                setCountGetPesquisa(0);
                setmsgPesquisaIndisponivel("Ocorreu um erro inesperado. Por favor, atualize a página e tente novamente.");
                setTypePesquisaIndisponivel('danger');
            }
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const data = formValues[name] || {};
        const isCheckBox = type === 'checkbox';

        if (isCheckBox) {
            data[value] = checked;
        }

        const newValue = isCheckBox ? data : value;
        setFormValues({ ...formValues, [name]: newValue });
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        try {
            setLoaderFormSubmit(true);
            setMessageSubmitButton("Enviando Respostas...")

            const formData = new FormData(e.target);
            let respostas = Object.fromEntries(formData);
            Object.entries(respostas).every(async (resposta, i, pos) => {
                await api.post(`/perguntas-realizacao/${idRealizacaoPesquisa}/respostas`, {
                    pergunta: resposta[0],
                    resposta: resposta[1]
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-token-pesquisa': tokenRealizacaoPesquisa,
                        },
                        timeout: 5000, //5 SEGUNDOS
                    })
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (pos.length - 1 === i) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Pesquisa respondida com sucesso!',
                                    timer: 2000
                                })
                                    .then(() => {
                                        api.post(`/perguntas-realizacao/${idRealizacaoPesquisa}/encerrar`, {}, {
                                            headers: {
                                                'x-token-pesquisa': tokenRealizacaoPesquisa
                                            },
                                            timeout: 5000, //5 SEGUNDOS
                                        })
                                        setPesquisaExist(false);
                                        setmsgPesquisaIndisponivel('Pesquisa respondida com sucesso! Obrigado.');
                                        setTypePesquisaIndisponivel('success');
                                        setShowMessage(true);
                                        setTimeout(() => {
                                            window.close();
                                        }, 3000)
                                    })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro inesperado.',
                                text: 'Ocorreu um erro inesperado ao enviar as respostas. Por favor, atualize a página e tente novamente.',
                            })
                                .then(() => {
                                    setLoaderFormSubmit(false);
                                    setMessageSubmitButton("Enviar Respostas");
                                })
                            return false;
                        }

                    })
                    .catch(() => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro inesperado.',
                            text: 'Ocorreu um erro inesperado ao enviar as respostas. Por favor, atualize a página e tente novamente.',
                        })
                            .then(() => {
                                setLoaderFormSubmit(false);
                                setMessageSubmitButton("Enviar Respostas");
                            })
                    });
            })
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erro inesperado.',
                text: 'Ocorreu um erro inesperado ao enviar as respostas. Por favor, atualize a página e tente novamente.',
            })
                .then(() => {
                    setLoaderFormSubmit(false);
                    setMessageSubmitButton("Enviar Respostas");
                })
        }

    }

    return (
        <div>
            <div className="container mt-5">
                <div className="row justify-content-center py-7 px-0 py-lg-8">
                    <div className="col-lg-7 col-md-8">
                        <div className="card my-5">
                            <div className="card-body">
                                {loader && (
                                    <Loader loaderMessage={loaderMessage} />
                                )}
                                {(!pesquisaExist && showMessage) && (
                                    <PesquisaIndisponivel mensagem={msgPesquisaIndisponivel} type={typePesquisaIndisponivel} />
                                )}
                                {pesquisa && (
                                    (
                                        <form onSubmit={handleSubmitForm} hidden={!pesquisaExist}>
                                            <Header dadosPesquisa={pesquisa.pesquisa} />
                                            <div>
                                                {
                                                    pesquisa.versao_atual.perguntas?.map((pergunta, index) => {

                                                        let condicionais = (pergunta.condicionais) ? true : false;

                                                        if (condicionais) {

                                                            let perguntaBaseId = pergunta.condicionais[0].pergunta;
                                                            
                                                            let perguntasBaseRespostas = [];
                                                            
                                                            pergunta.condicionais.forEach((condicional) => {
                                                                perguntasBaseRespostas.push(condicional.valor);
                                                            });

                                                            let resposta = formValues[perguntaBaseId];

                                                            if (perguntasBaseRespostas.includes(resposta)) {
                                                                return (
                                                                    <Pergunta
                                                                        key={pergunta.id}
                                                                        numero_pergunta={index + 1}
                                                                        dados={pergunta}
                                                                        handleInputChange={handleInputChange}
                                                                        formValues={formValues}
                                                                    />)
                                                            }

                                                        } else {
                                                            return (
                                                                <Pergunta
                                                                    key={pergunta.id}
                                                                    numero_pergunta={index + 1}
                                                                    dados={pergunta}
                                                                    handleInputChange={handleInputChange}
                                                                    formValues={formValues}
                                                                />)
                                                        }

                                                        return null;

                                                    })
                                                }
                                            </div>
                                            <EnviarRespostas handleSubmitForm={handleSubmitForm} loaderFormSubmit={loaderFormSubmit} messageButton={messageSubmitButton} />
                                        </form>
                                    )
                                )}
                            </div>
                            {/* <div className="text-center my-3">
                                <button
                                    onClick={handleCloseWindow}
                                    className="btn btn-primary col-md-4 p-2"
                                >
                                    <p className="m-0">Fechar</p>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Formulario